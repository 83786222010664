import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEO {
  description?: string;
  lang?: string;
  // meta: Array<string>;
  title?:string;
}

const SEO = ({
  description = '', lang = 'en', title = '',
}: SEO) => {
  const { site: { siteMetadata: meta } } = useStaticQuery(
    graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
              siteUrl
            }
          }
        }
      `,
  );

  const metaDescription = description || meta.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
        // {
        //   name: 'twitter:card',
        //   content: 'summary',
        // },
        // {
        //   name: 'twitter:creator',
        //   content: site.author,
        // },
        // {
        //   name: 'twitter:title',
        //   content: title,
        // },
        // {
        //   name: 'twitter:description',
        //   content: metaDescription,
        // },
      // ].concat(meta)}
    >
      <link rel="canonical" href={meta.siteUrl} />
    </Helmet>
  );
};

export default SEO;
