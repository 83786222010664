import React from 'react';
import {
  makeStyles, Theme, Button as MuiButton, ButtonBaseProps,
} from '@material-ui/core';

const colorMap: {[key in Extract<ButtonProps['color'], string>]: {body: keyof Theme['palette']['colors'], shadow: string}} = {
  blue: {
    body: 'gradient_blue',
    shadow: 'rgba(122, 136, 229, 0.2)',
  },
  green: {
    body: 'gradient_green',
    shadow: 'rgba(86, 191, 122, 0.2)',
  },
  purple: {
    body: 'gradient_purple',
    shadow: 'rgba(200, 100, 150, 0.2)',
  },
};

const useStyles = makeStyles<Theme, Pick<ButtonProps, 'color'>>((theme:Theme) => ({
  contained: ({ color }) => ({
    padding: theme.spacing(5, 12),
    borderRadius: '500px',
    fontSize: '1rem',
    background: theme.palette.colors[colorMap[color].body],
    fontWeight: 700,
    color: 'white',
    textTransform: 'capitalize',
    boxShadow: 'none',
    filter: 'brightness(1.0)',
    transition: '200ms',
    '&:hover': {
      filter: 'brightness(1.1)',
    },
    '&:hover, &:focus': {
      boxShadow: `${colorMap[color].shadow} 0px 0px 0px 15px`,
    },
    '&:active': {
      filter: 'brightness(1.15)',
      boxShadow: `${colorMap[color].shadow} 0px 0px 0px 20px`,
    },
  }),
}));

interface ButtonProps extends ButtonBaseProps {
  children: string;
  color: 'blue' | 'green' | 'purple';
  fullWidth?: boolean;
}

const Button = ({
  children, color, fullWidth = true, ...props
}: ButtonProps) => {
  const classes = useStyles({ color });

  return (
    <MuiButton variant="contained" classes={classes} fullWidth={fullWidth} disableRipple disableElevation {...props}>{children}</MuiButton>
  );
};

export default Button;
